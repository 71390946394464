.App {
    width: 100%;
    height: 100%;
    background: url('foodbank.jpeg') no-repeat center center;
    background-size: cover;
    position: relative;
}

.App:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
}

.App .text {
    position: absolute;
    left: 0;
    width: 100%;
    font-size: 86px;
    color: rgba(255, 255, 255, 0.8);
    top: 30%;
    text-align: center;
    z-index: 2;
}

@media (max-width: 767px) {
    .App .text {
        font-size: 56px;
    }
}

@media (max-width: 374px) {
    .App .text {
        font-size: 36px;
    }
}